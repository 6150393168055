<template>
  <div class="rounded-xl pa-1 ">
    <div class="d-flex">
      <div class="text-h6 mr-2 redLight--text">
        {{ points }}
      </div>
      <v-rating
        x-small
        readonly
        length="10"
        v-model="points"
        color="redLight"
        background-color="grey"
        full-icon="mdi-checkbox-blank-circle"
        empty-icon="mdi-checkbox-blank-circle-outline"
        dense
      ></v-rating>
      <div class="ml-auto grey3--text font-weight-thin text-12">
        {{ date | date2 }}
      </div>
    </div>
    <div class="mt-n3 ml-5 grey1--text">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PenaltyComponent",
  components: {},
  props: {
    date: {
      type: String,
      required: false,
      default: "00.00.0022"
    },
    points: {
      type: Number,
      required: false,
      default: 0
    },
    description: {
      type: String,
      required: false,
      default: "нет описания"
    }
  },
  data: () => ({
    loading: false,
    shop: {}
  }),
  computed: {
    // ...mapState([""]),
  },
  watch: {},
  methods: {},
  created() {}
};
</script>

<style scoped></style>
