<template>
  <Indicator name="действует штраф" :progress="loading">
    <template v-slot:button> </template>
    <template v-slot:content>
      <div class="mt-2">
        <v-alert dense outlined type="error">
          Штрафные баллы
        </v-alert>
        <div class="mt-n3 grey3--text px-1">
          <div>
            За 3 месяца в сумме было набрано 9 штрафных баллов
          </div>
          <div class="mt-1">
            Оклад начисляется по статусу Юниор
          </div>
        </div>
      </div>
    </template>
  </Indicator>
</template>

<script>
import Indicator from "./IndicatorComponent";
// import GoToButton from "./GoToButtonComponent";
// import axios from "axios";

export default {
  name: "IndicatorEffectivenesDetail",
  components: {
    // GoToButton,
    Indicator
  },
  props: {
    user_id: {
      type: Number,
      required: false,
      default: null,
      validator: v => typeof v === "number"
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
      validator: v => typeof v === "boolean"
    }
  },
  data: () => ({
  }),
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>

<style scoped></style>
