<template>
  <div
    class="d-flex"
    style="zoom: 1.1; -moz-transform: scale(3); -moz-transform-origin: 0 0"
  >
    <v-card max-width="600" width="600">
      <div style="height: 60px"></div>
      <IndicatorPenaltyPoints
        v-if="user_data.addon && user_data.addon.includes('offence')"
        :user_id="user.id"
        :loading="loading"
      />
      <IndicatorPenaltyEffectiveness
        v-if="user_data.addon && user_data.addon.includes('efficiency')"
        :user_id="user.id"
        :loading="loading"
      />
      <IndicatorEffectiveness
        :disable_button="true"
        :user_id="user.id"
        :shop_id="shop"
        :key="user.id + shop"
      />
      <IndicatorPenalty :user_id="user.id" />
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IndicatorEffectiveness from "./IndicatorEffectiveness";
import IndicatorPenalty from "./IndicatorPenalty";
import IndicatorPenaltyPoints from "./IndicatorPenaltyPoints";
import IndicatorPenaltyEffectiveness from "./IndicatorPenaltyEffectiveness";
import { back_request } from "../../mixins/back";
// import axios from "axios";
export default {
  name: "Indicators",
  mixins: [back_request],
  components: {
    IndicatorPenaltyPoints,
    IndicatorPenaltyEffectiveness,
    IndicatorPenalty,
    IndicatorEffectiveness,
  },
  data: () => ({
    users: [],
    loading: false,
  }),
  computed: {
    ...mapState(["user", "months", "month", "year", "shop"]),

    user_data() {
      if (!this.users.length) return {};
      for (let el of this.users) if (el.id == this.user.id) return el;
      return {};
    },
  },
  methods: {
    add_data() {
      if (!this.shop) return;
      this.loading = true;
      this.get_store_users({
        shopID: this.shop,
        month: this.month,
        points: true,
        year: this.year,
      })
        .then((data) => (this.users = data.sellers || []))
        .then(() => (this.loading = false));
    },
  },
  watch: {
    shop() {
      this.add_data();
    },
    month() {
      this.add_data();
    },
    year() {
      this.add_data();
    },
  },
  created() {
    this.add_data();
  },
};
</script>

<style scoped>
.DelimiterIndicator {
  height: 18px;
}
</style>
