<template>
  <Indicator name="штрафы в выбранном периоде" :progress="loading"
             v-if="points.length">
    <template v-slot:button> </template>
    <template v-slot:content>
      <div v-for="(n, idx) in points" :key="idx">
        <PenaltyComponent
          :date="n.date"
          :points="n.count"
          :description="n.comment"
        />
      </div>
    </template>
  </Indicator>
</template>

<script>
import Indicator from "./IndicatorComponent";
import PenaltyComponent from "./PenaltyComponent";
import { back_request } from  "../../mixins/back";
// import GoToButton from "./GoToButtonComponent";
// import axios from "axios";

export default {
  name: "IndicatorShift",
  mixins: [back_request],
  components: {
    // GoToButton,
    Indicator,
    PenaltyComponent
  },
  props: {
    user_id: {
      type: Number,
      required: false,
      default: null,
      validator: v => typeof v === "number"
    }
  },
  data: () => ({
    loading: false,
    points: []
  }),
  computed: {},
  watch: {
    user_id () {
      this.add_points();
    }
  },
  methods: {
    add_points() {
      if (!this.user_id) return;
      let date = new Date()
      let finishdate = new Date(date.getFullYear(), date.getMonth() + 1, 1, 3);
      let startdate = new Date(date.getFullYear(), date.getMonth() - 3, 1, 3);
      this.loading = true;
      this.get_user_points(this.user_id, startdate, finishdate)
      .then(data => this.points = data)
      .then(() => this.loading = false);
    }
  },
  created() {
    this.add_points();
  }
};
</script>

<style scoped></style>
